/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content {
  --offset-bottom: auto!important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.my-custom-modal-css .modal-wrapper {
    height: 100%;
    /*top: 45%;*/
    position: absolute; 
    display: block;  
 }

.my-custom-modal2-css .modal-wrapper {
    height: 31%;
    top: 70%;
    position: absolute;
    display: block;
    border-radius: 10px;
}

.my-custom-modal3-css .modal-wrapper {
    height: 70%;
    top: 31%;
    position: absolute;
    display: block;
    border-radius: 10px;
}

 .my-full-modal-css .modal-wrapper {
 
    top: 60px;
    position: absolute; 
    display: block;  
 }

 .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: unset !important;
}
.mat-expansion-panel-spacing {
    margin: 0 !important;
}
.mat-expansion-panel-header {
    
    border-radius: unset;
    border-bottom: 1px solid #e6e5e7;
    margin-bottom: 10px;
}
.item-native {
    background: transparent !important;
}

.button-native {
    border-radius: 20px;
}

@font-face {   
  font-family: 'Mulish';    //This is what we are going to call
  src: url('./assets/fonts/Mulish-Bold.ttf');
}
